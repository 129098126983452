import { inject, Injectable } from '@angular/core';
import { IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';
import {
  hitAreaMapping,
  MarkerTruckChargingGrade,
  MarkerState,
  MarkerMSPProviderType,
  getTruckChargingGrade,
  getMSPProvider,
  IconKey,
} from '@pages/map/utils/icon-utils';

@Injectable()
export class MapIconsService {
  private mapUIStateService = inject(MapUiStateService);

  clusterIcons: Map<number, H.map.Icon> = new Map();
  clusterHoverIcons: Map<number, H.map.Icon> = new Map();

  markerIcons: Map<IconKey, H.map.Icon> = new Map();

  visibleCluster?: H.map.Marker[];

  activeChargePoint: {
    active: boolean;
    restoreIcon?: H.map.Icon;
    marker?: H.map.Marker;
  } = { active: false };

  constructor() {
    this.genereateClusterIcons();
    this.generateMarkerIcons();
  }

  getClusterIconSize(count: number): number {
    if (count >= 5000) return 5000;
    if (count >= 4000) return 4000;
    if (count >= 3000) return 3000;
    if (count >= 2000) return 2000;
    if (count >= 1000) return 1000;
    if (count >= 500) return 500;
    if (count >= 400) return 400;
    if (count >= 300) return 300;
    if (count >= 200) return 200;
    if (count >= 100) return 100;
    if (count >= 90) return 90;
    if (count >= 80) return 80;
    if (count >= 70) return 70;
    if (count >= 60) return 60;
    if (count >= 50) return 50;
    if (count >= 40) return 40;
    if (count >= 30) return 30;
    if (count >= 20) return 20;
    if (count >= 15) return 15;
    if (count >= 10) return 10;
    if (count >= 9) return 9;
    if (count >= 8) return 8;
    if (count >= 7) return 7;
    if (count >= 6) return 6;
    if (count >= 5) return 5;
    if (count >= 4) return 4;
    if (count >= 3) return 3;
    return 2; // Default
  }

  generateMarkerIcons() {
    // this gonna be new
    const markerMSPProviderTypes: MarkerMSPProviderType[] = ['MSP', 'NonMSP', 'Partner'];
    const markerTruckChargingGrades: MarkerTruckChargingGrade[] = ['Limited', 'Truck', 'Passcar'];
    const markerStates: MarkerState[] = ['Active', 'Default', 'Hover'];
    const iconRootPath = `../assets/icons/mapIcons/markerIcons/`;

    markerTruckChargingGrades.forEach((group) => {
      const iconPath = `${iconRootPath}${group.toLowerCase()}/`;

      markerMSPProviderTypes.forEach((provider) => {
        markerStates.forEach((state) => {
          const iconKey = `${group}-${provider}-${state}` as IconKey;
          const markerFileName = `${iconPath}${iconKey}.svg`;
          const hitAreaKey = `${group}-${state}`;
          const hitAreaCoords = hitAreaMapping[hitAreaKey] || [];

          if (hitAreaCoords.length > 0) {
            const icon = new H.map.Icon(markerFileName, {
              hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.POLYGON, hitAreaCoords),
            });
            this.markerIcons.set(iconKey, icon);
          } else {
            console.warn(`Hit area not defined for: ${hitAreaKey}`);
          }
        });
      });
    });
  }

  genereateClusterIcons() {
    const clusterSizes = [
      2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000, 2000, 3000, 4000,
      5000,
    ];
    clusterSizes.forEach((size) => {
      const svgUrl = `../assets/icons/mapIcons/clusterIcons/${size}map-group.svg`;
      const svgHoverUrl = `../assets/icons/mapIcons/clusterIcons/Hover/${size}map-group-hover.svg`;
      this.clusterIcons.set(
        size,
        new H.map.Icon(svgUrl, {
          hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.CIRCLE, [40, 40, 25]),
        }),
      );
      this.clusterHoverIcons.set(
        size,
        new H.map.Icon(svgHoverUrl, {
          hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.CIRCLE, [44, 44, 32]),
        }),
      );
    });
  }

  applyHoverStyleToClusterMarker(clusterMarker: H.map.Marker, weight: number) {
    let icon = this.clusterHoverIcons.get(this.getClusterIconSize(weight));
    if (icon) {
      clusterMarker.setIcon(icon);
    }
  }

  removeHoverStyleFromClusterMarker(clusterMarker: H.map.Marker, weight: number) {
    let icon = this.clusterIcons.get(this.getClusterIconSize(weight));
    if (icon) {
      clusterMarker.setIcon(icon);
    }
  }

  applyHoverStyles(mapMarker?: H.map.Marker, chargePoint?: IChargePointv2) {
    if (mapMarker) {
      // get correct hover style
      const data = mapMarker.getData();
      mapMarker.setIcon(this.getHoverIcon(data));
      this.highlightChargePointInTheListInListComponent(mapMarker.getData());
      return;
    }

    // issue todo
    if (chargePoint) {
      let matchedMarker = this.visibleCluster?.find((cp) => {
        let markerdata = cp.getData() as IChargePointv2;
        return markerdata.chargePointId === chargePoint.chargePointId;
      });

      try {
        matchedMarker?.setIcon(this.getHoverIcon(chargePoint));
      } catch {
        console.log('cant set marker icon for', matchedMarker);
      }
    }
  }

  removeHoverStyles(mapMarker?: H.map.Marker, chargePoint?: IChargePointv2) {
    if (mapMarker) {
      mapMarker.setIcon(this.getDefaultIcon(mapMarker.getData()));
      return;
    }

    if (chargePoint) {
      let matchedMarker = this.visibleCluster?.find((cp) => {
        let markerdata = cp.getData() as IChargePointv2;
        return markerdata.chargePointId === chargePoint.chargePointId;
      });

      try {
        matchedMarker?.setIcon(this.getDefaultIcon(matchedMarker.getData()));
        this.mapUIStateService.hoveredChargePoint.set(undefined);
      } catch {
        console.log('cant revert to default icon after pointerleaves from marker:', matchedMarker);
      }
    }
  }

  updateActiveChargePointIcon(chargePoint: IChargePointv2, selectedMarker?: H.map.Marker) {
    // first restore previously selected icon
    this.resetPreviouslySelectedChargePointIcon();

    if (selectedMarker) {
      try {
        this.activeChargePoint = {
          marker: selectedMarker,
          restoreIcon: this.getDefaultIcon(selectedMarker.getData()),
          active: true,
        };
        selectedMarker.setIcon(this.getActiveIcon(chargePoint));
      } catch {
        console.log('cant set active icon');
      }
      return;
    } else if (selectedMarker === undefined) {
      // CPO selected from list so we dont which marker we need to highlight
      // we need to find from the visibleclusters
      const matchedMarker = this.visibleCluster?.find((marker) => {
        const data = marker.getData() as IChargePointv2;
        return data.chargePointId === chargePoint.chargePointId;
      });

      // issue todo
      if (matchedMarker) {
        this.activeChargePoint = {
          marker: matchedMarker,
          restoreIcon: this.getDefaultIcon(matchedMarker.getData()),
          active: true,
        };

        try {
          matchedMarker?.setIcon(this.getActiveIcon(chargePoint));
        } catch {
          console.log('cant set active icon for', matchedMarker);
        }
      }
    }
  }

  getDefaultIcon(chargePoint: IChargePointv2): H.map.Icon {
    let activeIconKey = this.getIconKey(chargePoint, 'Default');
    return this.markerIcons.get(activeIconKey)!;
  }

  getHoverIcon(chargePoint: IChargePointv2): H.map.Icon {
    let activeIconKey = this.getIconKey(chargePoint, 'Hover');
    return this.markerIcons.get(activeIconKey)!;
  }

  getActiveIcon(chargePoint: IChargePointv2): H.map.Icon {
    let activeIconKey = this.getIconKey(chargePoint, 'Active');
    return this.markerIcons.get(activeIconKey)!;
  }

  getIconKey(markerData: IChargePointv2, state?: MarkerState): IconKey {
    // icon
    let iconKeyString = '';
    iconKeyString += this.getMarkerTruckChargingGrade(markerData) + '-';
    iconKeyString += getMSPProvider(markerData.name) + '-';

    if (state) {
      return (iconKeyString += state) as any;
    } else {
      return (iconKeyString += 'Default') as any;
    }
  }

  getMarkerTruckChargingGrade(markerData: IChargePointv2): MarkerTruckChargingGrade {
    return getTruckChargingGrade(markerData.truckChargingGrade);
  }

  highlightChargePointInTheListInListComponent(chargePoint: IChargePointv2) {
    this.mapUIStateService.hoveredChargePoint.set(chargePoint);
  }

  revertHighlightedChargePointInListComponent() {
    this.mapUIStateService.hoveredChargePoint.set(undefined);
  }

  resetPreviouslySelectedChargePointIcon() {
    // removes active icon from previously selected cp, and restores it to default icon
    if (this.activeChargePoint?.marker) {
      this.activeChargePoint.marker.setIcon(this.getDefaultIcon(this.activeChargePoint?.marker.getData()));
    }
    this.setActiveChargePointToInitialValue();
  }

  setActiveChargePointToInitialValue() {
    this.activeChargePoint = {
      active: false,
      restoreIcon: undefined,
      marker: undefined,
    };
  }
}
