export type partnerName = 'milence' | 'rifil' | '';

export function isPartner(chargePointName: string): partnerName {
  if (chargePointName.toLocaleLowerCase().includes('milence')) {
    return 'milence';
  }
  if (chargePointName.toLocaleLowerCase().includes('rifil')) {
    return 'rifil';
  }
  return '';
}
