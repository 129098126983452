import { HttpClient } from '@angular/common/http';
import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { DatadogMetricLoggerService } from '@core/services/datadog-metric-logger.service';
import { environment } from '@environments/environment';
import { ClusteredChargePoints } from '@pages/map/interfaces/chargePointClustering';
import { chargePointDetails, IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { ChargePointApiService } from '@pages/map/services/charge-point-api.service';
import { ClusteringService } from '@pages/map/services/clustering.service';
import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';
import { MarkerService } from '@pages/map/services/marker.service';
import { isPartner } from '@pages/map/utils/partner-filtering';
import { first, tap } from 'rxjs';

export const InitialMapPosition = {
  lat: '59.165692143635496',
  long: '17.61991628465645',
  radius: 400,
};

@Injectable()
export class ChargePointService {
  private readonly chargePointsURL = environment.chargepoint;
  private queryParams: string = '';
  private httpClient = inject(HttpClient);
  private datadogMetricLogger = inject(DatadogMetricLoggerService);
  private markerService = inject(MarkerService);
  private chargePointAPI = inject(ChargePointApiService);
  private mapUIControls = inject(HereMapsUiControlsService);
  private clusteringService = inject(ClusteringService);

  private mapUIState = inject(MapUiStateService);

  chargePoints = signal<IChargePointv2[]>([]);

  showTruckChargePoints = this.chargePointAPI.showTruckChargePoints;
  showPassengerCarChargingPoints = this.chargePointAPI.showPassengerCarChargingPoints;
  showLimitedTruckChargingPoints = this.chargePointAPI.showLimitedTruckChargingPoints;

  visibleChargePoints = signal<IChargePointv2[]>([]);
  visibleMarkers = signal<any>([]);

  calculateVisibleChargePoints() {
    const a = this.chargePoints().filter((cpo) => {
      return this.mapUIControls.boundingBox?.containsPoint({
        lat: cpo.coordinates.latitude,
        lng: cpo.coordinates.longitude,
      });
    });

    this.visibleChargePoints.set(a);
  }

  initialEffectRun = true;
  constructor() {
    effect(() => {
      console.log('effect call');
      if (this.initialEffectRun) {
        this.registerEffects();
        this.initialEffectRun = false;
      } else {
        this.mapUIControls.reCreateCluster = true;
        this.getChargePoints();

        // this.chargePointAPI.get3ClusteredChargePoints();
        // this.getClusteredChargePoints()
      }
    });
  }

  registerEffects() {
    this.showLimitedTruckChargingPoints();
    this.showPassengerCarChargingPoints();
    this.showTruckChargePoints();
  }

  setCarChargingFilter(value: boolean) {
    this.showPassengerCarChargingPoints.set(value);
  }

  setLimitedTruckChargePoints(value: boolean) {
    this.showLimitedTruckChargingPoints.set(value);
  }

  // setTruckBuiltFilter(value: boolean) {}

  getClusteredChargePoints() {
    this.chargePointAPI
      .getClusteredChargePoints()
      .pipe(first())
      .subscribe({
        next: (chargePoints: ClusteredChargePoints[]) => {
          this.markerService.setMarkerRenderingLogic(chargePoints);
        },
        error: () => {},
        complete: () => {},
      });
  }

  mapViewChanged() {
    this.calculateVisibleChargePoints();
    this.clusteringService.calculateVisibleClusters();

    if (false) {
      //temp demo
      console.log('using clusterEndPoint');
      // this.clusteringService.get3ClusteredChargePoints();
      // this.getClusteredChargePoints();
    } else {
      console.log('Using list Endpoint');
      this.getChargePoints();
    }
  }

  getChargePoints() {
    if (this.mapUIControls.reCreateCluster) {
      this.chargePointAPI
        .getChargePointsList()
        .pipe(
          first(),
          tap((res) => {
            this.datadogMetricLogger.chargePointApiPerformance(
              res.length,
              this.mapUIControls.latitude,
              this.mapUIControls.longitude,
              this.mapUIControls.radius,
            );
          }),
        )
        .subscribe({
          next: (chargePoints: IChargePointv2[]) => {
            const sortedCPOS = this.sortChargePoints(chargePoints);
            this.chargePoints.set(sortedCPOS);
            this.calculateVisibleChargePoints();
            this.markerService.setMarkerRenderingLogic(chargePoints);
          },
          error: () => {},
          complete: () => {},
        });
    }
  }

  sortChargePoints(chargePoints: IChargePointv2[]): IChargePointv2[] {
    const sortedbyMaxPower = this.sortCPOsByMaxPower(chargePoints);

    return this.sortCPOsByPartners(sortedbyMaxPower);
  }

  sortCPOsByMaxPower(chargePoints: IChargePointv2[]): IChargePointv2[] {
    return chargePoints.sort((a, b) => b.maxPower - a.maxPower);
  }

  sortCPOsByPartners(chargePoints: IChargePointv2[]) {
    // partner CPOS should appear on top of the list
    const partnerCPO = (cpo: IChargePointv2) => isPartner(cpo.name);
    const reorderedItems = chargePoints
      .filter(partnerCPO)
      .concat(chargePoints.filter((chargePoint) => !partnerCPO(chargePoint)));
    return reorderedItems;
  }

  getChargePointDetail(chargePointId: string) {
    return this.chargePointAPI.getChargePointDetail(chargePointId);
  }
}
