import { isPartner } from '@pages/map/utils/partner-filtering';

export type MarkerMSPProviderType = 'MSP' | 'NonMSP' | 'Partner';
export type MarkerTruckChargingGrade = 'Limited' | 'Truck' | 'Passcar';
export type MarkerState = 'Active' | 'Default' | 'Hover';

export type IconKey = `${MarkerTruckChargingGrade}-${MarkerMSPProviderType}-${MarkerState}`;

export const hitAreaMapping: Record<string, number[]> = {
  'Truck-Default': [
    14, 50.187, 24, 60.017, 32, 52.256, 35.5, 48.635, 40, 43.461, 43, 39.322, 45.5, 35.182, 47, 31.043, 47.5, 28.974,
    48, 25.869, 48, 24.835, 47.5, 20.178, 46.5, 16.556, 44.5, 11.9, 40.5, 6.726, 36.5, 3.622, 31, 1.035, 26, 0, 22.5, 0,
    19, 0.517, 15.5, 1.552, 11.5, 3.622, 7.5, 6.726, 5, 9.83, 2.5, 14, 1, 18.109, 0, 22.765, 0, 25.869, 0.5, 30.009, 2,
    34.148, 4.5, 38.804, 8, 43.461, 14, 50.187,
  ],
  'Truck-Hover': [
    16.333, 56.862, 28, 68, 37.333, 59.207, 41.417, 55.104, 46.667, 49.241, 50.167, 44.552, 53.083, 39.862, 54.833,
    35.172, 55.417, 32.828, 56, 29.31, 56, 28.138, 55.417, 22.862, 54.25, 18.759, 51.917, 13.483, 47.25, 7.621, 42.583,
    4.103, 36.167, 1.172, 30.333, 0, 26.25, 0, 22.167, 0.586, 18.083, 1.759, 13.417, 4.103, 8.75, 7.621, 5.833, 11.138,
    2.917, 15.828, 1.167, 20.517, 0, 25.793, 0, 29.31, 0.583, 34, 2.333, 38.69, 5.25, 43.965, 9.333, 49.241, 16.333,
    56.862,
  ],
  'Limited-Default': [
    12, 43, 20.5, 50.5, 33.5, 37.5, 39, 28, 41, 18.5, 40.5, 13.5, 40, 9.5, 38, 4.5, 34, 1.5, 29.5, 0, 9, 0, 3, 3.5, 0,
    10, 0, 21, 3, 31, 12, 43,
  ],
  'Limited-Hover': [
    14.342, 51.941, 24.5, 61, 40.037, 45.297, 46.61, 33.822, 49, 22.347, 48.402, 16.307, 47.805, 11.475, 45.415, 5.436,
    40.634, 1.812, 35.256, 0, 10.756, 0, 3.585, 4.228, 0, 12.079, 0, 25.366, 3.585, 37.445, 14.342, 51.941,
  ],
  'Passcar-Default': [
    9, 31, 16, 35, 19.5, 33.5, 23, 31, 28, 26.5, 30.5, 22, 31.5, 18.5, 31.5, 6, 30.5, 3, 28, 1, 24.5, 0, 18, 0, 11, 0,
    6, 0.5, 3, 1.5, 1, 4.5, 0, 7.5, 0.5, 19.5, 2, 23, 4.5, 27, 9, 31,
  ],
  'Passcar-Hover': [
    11.143, 38.971, 19.809, 44, 24.143, 42.114, 28.476, 38.971, 34.667, 33.314, 37.762, 27.657, 39, 23.257, 39, 7.543,
    37.762, 3.771, 34.667, 1.257, 30.333, 0, 22.286, 0, 13.619, 0, 7.429, 0.629, 3.714, 1.886, 1.238, 5.657, 0, 9.429,
    0.619, 24.514, 2.476, 28.914, 5.571, 33.943, 11.143, 38.971,
  ],
};

// export const hitAreaMapping: Record<string, number[]> = {
//   'Truck-Default': [
//     37, 1.5, 28, 0, 21, 1, 15.5, 3, 10, 6.5, 5.5, 11.5, 2, 17.5, 0.5, 22.5, 0, 28, 1, 33, 4, 40.5, 12.5, 51.5, 27.5,
//     65.5, 35, 58, 46.5, 46.5, 52, 39, 54.5, 32, 55, 25.5, 52.5, 16, 49, 10.5, 44.5, 5.5, 37, 1.5,
//   ],
//   'Truck-Active': [
//     55.5, 4.5, 38, 0, 26, 2, 10.5, 10.5, 1.5, 25.5, 0, 41, 7.5, 59.5, 38, 88.5, 66, 59.5, 74, 46.5, 75.5, 29, 67.5, 14,
//     55.5, 4.5,
//   ],
//   'Truck-Hover': [
//     55.5, 4.5, 38, 0, 26, 2, 10.5, 10.5, 1.5, 25.5, 0, 41, 7.5, 59.5, 38, 88.5, 66, 59.5, 74, 46.5, 75.5, 29, 67.5, 14,
//     55.5, 4.5,
//   ],
//   'Limited-Default': [
//     29, 0, 10, 0, 4.986, 2.279, 1, 6.5, 0, 19, 3, 30.5, 10, 41, 20, 52.5, 31, 41, 37, 30.5, 40, 19, 38, 6.5, 34.5, 2.5,
//     29, 0,
//   ],
//   'Limited-Active': [
//     37.25, 0, 12.5, 0, 6.232, 2.909, 1.25, 8.295, 0, 24.248, 3.75, 38.924, 12.5, 52.324, 25, 67, 39.75, 52.324, 47.25,
//     38.924, 51, 24.248, 48.5, 8.295, 44.125, 3.19, 37.25, 0,
//   ],
//   'Limited-Hover': [
//     37.25, 0, 12.5, 0, 6.232, 2.909, 1.25, 8.295, 0, 24.248, 3.75, 38.924, 12.5, 52.324, 25, 67, 39.75, 52.324, 47.25,
//     38.924, 51, 24.248, 48.5, 8.295, 44.125, 3.19, 37.25, 0,
//   ],
//   'Passcar-Default': [
//     4, 1, 1.5, 3.5, 0, 8.5, 0, 18, 2, 25, 9, 32, 16, 36.5, 23.5, 32, 30.5, 25, 32.5, 18, 32.5, 8.5, 31.5, 3.5, 29, 1.5,
//     24, 0, 11, 0, 4, 1,
//   ],
//   'Passcar-Active': [
//     6.042, 1.315, 2, 4.603, 0.107, 11.178, 0.5, 24, 1, 28.5, 3.519, 32.877, 7, 38, 12.349, 42.082, 21.18, 48, 30.642,
//     42.082, 35.5, 38, 39.473, 32.877, 41.5, 28.5, 42.5, 23.5, 42.5, 11.178, 41.996, 8, 40.734, 4.603, 37.5, 1.315,
//     31.273, 0, 14.873, 0, 6.042, 1.315,
//   ],
//   'Passcar-Hover': [
//     6.042, 1.315, 2, 4.603, 0.107, 11.178, 0.5, 24, 1, 28.5, 3.519, 32.877, 7, 38, 12.349, 42.082, 21.18, 48, 30.642,
//     42.082, 35.5, 38, 39.473, 32.877, 41.5, 28.5, 42.5, 23.5, 42.5, 11.178, 41.996, 8, 40.734, 4.603, 37.5, 1.315,
//     31.273, 0, 14.873, 0, 6.042, 1.315,
//   ],
// };

export function getTruckChargingGrade(chargingGrade: number): MarkerTruckChargingGrade {
  switch (chargingGrade) {
    case 1:
      return 'Passcar';
    case 2:
      return 'Limited';
    case 4:
      return 'Truck';
    default:
      return 'Passcar';
  }
}

export function getMSPProvider(chargePointName: string): MarkerMSPProviderType {
  //currently we dont have any logic to filter MSP
  // it is statically checked if it is a partner or not
  // if it is not a partner it is MSP.
  // Because as today we do not get any NONMSP chargepoints
  // let status = isPartner(chargePointName);

  if (isPartner(chargePointName)) {
    return 'Partner';
  } else {
    return 'MSP';
  }
}
