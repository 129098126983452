import { inject, Injectable } from '@angular/core';
import { IChargePointv2 } from '@pages/map/interfaces/chargepoints';

import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';
import { MapIconsService } from '@pages/map/services/map-icons.service';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';

@Injectable()
export class ClusteringService {
  private mapUIControls = inject(HereMapsUiControlsService);
  private mapUIStateService = inject(MapUiStateService);
  private mapIconsService = inject(MapIconsService);

  currentClusterlayer: H.map.layer.ObjectLayer = {} as any;

  clusteredDataProvider: H.clustering.Provider;

  visibleCluster: H.map.Marker[];

  clusterGroupZoomLevel1 = new H.map.Group();
  clusterGroupZoomLevel2 = new H.map.Group();
  clusterGroupZoomLevel3 = new H.map.Group();

  hoverSupport = this.mapUIStateService.hoverFeatureAvailable;

  constructor() {}

  setTheme(): H.clustering.ITheme {
    let Theme: H.clustering.ITheme = {
      getClusterPresentation: (cluster) => {
        const clusterSize = cluster.getWeight();
        var clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: this.mapIconsService.clusterIcons.get(this.mapIconsService.getClusterIconSize(clusterSize)),
          data: cluster,
          volatility: true,
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        });

        if (this.hoverSupport) {
          this.attachEventListenerForClusterMarkers(clusterMarker, clusterSize);
        }

        //need to Implement click logic here i guess
        return clusterMarker;
      },
      getNoisePresentation: (noisePoint: H.clustering.INoisePoint): H.map.Object => {
        let data = noisePoint.getData() as IChargePointv2;

        const iconKey = this.mapIconsService.getIconKey(data);

        let noiseMarker = new H.map.Marker(
          noisePoint.getPosition(),
          {
            icon: this.mapIconsService.markerIcons.get(iconKey)!,
            data: data,
            volatility: true,
            min: noisePoint.getMinZoom(),
          },
          // Use min zoom from a noise point
          // to show it correctly at certain zoom levels:
        );

        if (this.hoverSupport) {
          this.attachEventListenerForIndividualMarkers(noiseMarker);
        }
        noiseMarker.setData(data);

        return noiseMarker;
      },
    };

    return Theme;
  }

  attachEventListenerForClusterMarkers(marker: H.map.Marker, clusterSize: number) {
    marker.addEventListener('pointerenter', (event: H.mapevents.Event) => {
      // hover
      const eventMarker = event.target as H.map.Marker;
      this.mapUIControls.mapInstance.getElement().style.cursor = 'pointer';
      this.mapIconsService.applyHoverStyleToClusterMarker(marker, clusterSize);
    });

    marker.addEventListener('pointerleave', (event: H.mapevents.Event) => {
      // restore to default
      const eventMarker = event.target as H.map.Marker;
      this.mapUIControls.mapInstance.getElement().style.cursor = 'unset';
      this.mapIconsService.removeHoverStyleFromClusterMarker(marker, clusterSize);
    });
  }

  attachEventListenerForIndividualMarkers(marker: H.map.Marker) {
    marker.addEventListener('pointerenter', (event: H.mapevents.Event) => {
      // hover
      const eventMarker = event.target as H.map.Marker;
      this.mapUIControls.mapInstance.getElement().style.cursor = 'pointer';
      if (this.isSameMarker(eventMarker)) {
        return;
      } else {
        this.mapIconsService.applyHoverStyles(eventMarker);
      }
    });

    marker.addEventListener('pointerleave', (event: H.mapevents.Event) => {
      // restore to default
      const eventMarker = event.target as H.map.Marker;
      this.mapUIControls.mapInstance.getElement().style.cursor = 'unset   ';
      if (this.isSameMarker(eventMarker)) {
      } else {
        this.mapIconsService.removeHoverStyles(eventMarker);
        this.mapUIStateService.hoveredChargePoint.set(undefined);
      }
    });
  }

  removeClustering() {
    if (this.currentClusterlayer) {
      this.mapUIControls.mapInstance.removeLayer(this.currentClusterlayer);
    }
  }

  createCluster(chargePoints: IChargePointv2[]) {
    // remove previous clusters
    this.removeClustering();
    let dataPoints: H.clustering.DataPoint[] = chargePoints.map((chargePoint) => {
      return new H.clustering.DataPoint(
        chargePoint.coordinates.latitude,
        chargePoint.coordinates.longitude,
        1,
        chargePoint,
      );
    });

    this.clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        minWeight: 2,
        strategy: H.clustering.Provider.Strategy.FASTGRID,
        eps: 90,
      },
      theme: this.setTheme(),
    });

    let layer = new H.map.layer.ObjectLayer(this.clusteredDataProvider);

    //Clustering markers visible zoom
    // we dont need this if we decide to use clustering only
    // layer.setMax(9);

    this.currentClusterlayer = layer;

    this.mapUIControls.mapInstance.addLayer(layer);
    this.mapUIControls.clusterLayer = layer;
    // this.clusteredDataProvider.setDataPoints(dataPoints);
    this.mapUIControls.reCreateCluster = false;

    this.attachClusterTapListener();
  }

  calculateVisibleClusters() {
    const visibleObjects = this.clusteredDataProvider?.requestMarkers(
      this.mapUIControls.boundingBox!,
      this.mapUIControls.zoomLevel(),
      true,
      false,
    );

    this.mapIconsService.visibleCluster = visibleObjects;
  }

  attachClusterTapListener() {
    this.clusteredDataProvider.addEventListener(
      'tap',
      (event: H.mapevents.Event) => {
        // Get the clicked marker - either a cluster or IChargePointv2
        const marker = event.target as H.map.Object;

        const point = marker.getData() as H.clustering.ICluster | IChargePointv2;
        if ('isCluster' in point && point.isCluster()) {
          // It's a cluster

          const cluster = point as H.clustering.ICluster;

          // zoom when cluster clicked until that cluster breaks apart
          // this.mapUIControls.navigateToPoint(cluster.getPosition(), cluster.getMaxZoom() + 1);

          this.setZoomAfterClusterTap(cluster.getPosition());
          // probably zoom in until next cluster formation
          point.forEachDataPoint((dataPoint: H.clustering.INoisePoint) => {
            const position = dataPoint.getPosition();
          });
        } else {
          // It's an IChargePointv2  type load load charge point details
          const chargePoint = point as IChargePointv2;
          const marker2 = marker as H.map.Marker;

          this.mapIconsService.updateActiveChargePointIcon(chargePoint, marker2);

          this.mapUIStateService.selectedChargePoint.set(chargePoint);
          this.mapUIStateService.showChargePointDetails.set(true);
          this.mapUIStateService.hideFilterMenu();

          this.mapUIControls.navigateToPoint(
            {
              lat: chargePoint.coordinates.latitude,
              lng: chargePoint.coordinates.longitude,
            },
            16,
          );
        }
      },
      false,
    );
  }

  setZoomAfterClusterTap(clusterPosition: H.geo.Point) {
    //
    const mapZoom = this.mapUIControls.mapInstance.getZoom();

    if (mapZoom < 7) {
      this.mapUIControls.navigateToPoint(clusterPosition, mapZoom + 4);
      return;
    }
    if (mapZoom < 10) {
      this.mapUIControls.navigateToPoint(clusterPosition, mapZoom + 3);
      return;
    }
    if (mapZoom < 23) {
      this.mapUIControls.navigateToPoint(clusterPosition, mapZoom + 1);
      return;
    }
  }

  isSameMarker(marker: H.map.Marker): boolean {
    //In case of hover for same marker, or active marker
    // this function compares if the marker is same as currently hovered/activated
    if (this.mapIconsService.activeChargePoint?.marker) {
      const cpoInfo = marker.getData() as IChargePointv2;
      const activeCpoInfo = this.mapIconsService.activeChargePoint.marker.getData() as IChargePointv2;

      if (cpoInfo.chargePointId === activeCpoInfo.chargePointId) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
